<template>
  <div class="retrieve">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>通过以下流程找回密码</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>通过以下流程找回密码</h3>
          </li>
          <li class="active">
            <img src="~/static/login/02_active.png" alt="">
            <h3>验证信息</h3>
          </li>
          <li>
            <img src="~/static/login/03.png" alt="">
            <h3>重置密码</h3>
          </li>
        </ul>
      </div>
      <el-form :model="form" :rules="error" label-width="120px" ref="forms" class="form" :hide-required-asterisk="true">
        <el-form-item label="电子邮箱" prop="iphone">
          <el-input v-model="form.email" placeholder="请输入已绑定的电子邮箱" class="wrap_input w336"></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button type="primary" plain class="w180" @click="goRouter">上一步</el-button>
          <el-button type="primary" class="w180" @click="nextSubmit">提交</el-button>
        </el-form-item>
      </el-form>
      <el-dialog
          :visible.sync="isHide"
          width="288px"
          :show-close="false"
          :before-close="handleClose">
        <div class="dialog_content">
          <img src="~/static/login/success1.png" alt="">
          <h3>提交成功，请查看电子邮箱</h3>
          <div class="btn flex">
            <el-button type="primary" @click="isHide = false" class="w130">返回首页</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "phone",
  data(){
    return{
      form: {
        email: '',
      },
      error: {
        email: [
          {
            required: true,
            message: "该邮箱未绑定账号，请检查输入是否正确",
            trigger: "blur",
          },
        ],
      },
      isHide:false,
      active:0,
    }
  },
  methods:{
    nextSubmit(){
      this.$refs.forms.validate(async (valid) => {
        if (!valid){
          this.$message.error("信息填写不完整");
        }else{
          this.$api.findPwdByEmail(this.form).then(res =>{
            if (res.data.success){
              this.$message.success('邮箱发送成功，请查看邮件，修改密码！')
            }else{
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    goRouter(){
      this.$router.push('/retrievePassword')
    },
    handleClose(){
      this.isHide = false;
    },
  }
}
</script>

<style scoped lang="less">
.retrieve {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }

      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28A46D;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #CCD0CE;
        }
        li.active::after{
          background: #28A46D;
        }
        li:first-child::after {
          display: none;
        }
      }
    }
    /deep/ .form {
      width: 792px;
      margin: 26px auto 0 auto;
      padding: 36px 109px;
      .el-form-item {
        margin-bottom: 36px;
        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99A09D;
        }

        .el-input-group--append {
          border: 1px solid #CECFD0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }
        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716C;
            padding-left: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #00924b;
          }
        }
      }
      .btn{
        margin-top: 126px;
        transform: translateX(-24px);
        .el-button{
          margin-right: 24px;
        }
      }
    }
  }
  .el-dialog{
    .dialog_content{
      text-align: center;
      h3{
        font-size: 18px;
        margin-top: 10px;
      }
      p{
        color: #66716C;
        padding: 6px 0;
      }
      .btn{
        justify-content: center;
        margin-top: 10px;
        .el-button{
          width: 102px;
        }
        .el-button:nth-of-type(1){
          margin-right: 20px;
        }
      }
      .el-button{
        margin-top: 16px;
        width: 132px;
      }

    }
  }
}
</style>
